var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "container",
      attrs: { "grid-list-md": "", "text-xs-center": "" }
    },
    [
      _c(
        "v-flex",
        { staticClass: "ma-5", attrs: { md12: "", xs12: "" } },
        [
          _c("v-card", { staticClass: "card pa-4" }, [
            _c("div", { staticClass: "header" }, [
              _c(
                "h1",
                {
                  staticClass:
                    "secondary-text text-lg-left form-title d-flex align-center"
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { "x-large": "", color: "#000" }
                    },
                    [_vm._v("mdi-cancel")]
                  ),
                  _vm._v(" Geen toegang ")
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }